import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Logo from '../images/logo2.png'
import emailjs from 'emailjs-com';

//service_wjjryxt
function Contactus() {
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        message: ''
    });

    const [EmailSent, setEmailSent] = useState(false);
    const [isMobileValid, setIsMobilevalid] = useState(false);
    const [mobileValidation, setMobileValidation] = useState('');


    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "mobile") {
            if (/^\d*$/.test(value) && value.length <= 10) {
                setIsMobilevalid(false);
            }
            else {
                setIsMobilevalid(true);

            }
        }
        setFormData({ ...formData, [name]: value });

    };


    function onsubmit(e) {
        e.preventDefault();
        setEmailSent(true);
        if (!isMobileValid) {
            setMobileValidation('Please enter a valid mobile number');
            return
        }
        else {


            emailjs
                .send(
                    'service_wjjryxt',
                    'template_nc8zpfl',
                    formData,
                    'xSbRtosPxNObWhW4i'
                )
                .then(
                    (result) => {
                        console.log(result.text);
                    },
                    (error) => {
                        console.log(error.text);
                    }
                );
        }
    }


    return (
        <>
            <div className="con-image">
                <img src={Logo} alt='Azion' style={{ "width": "50%", "minWidth": "250px" }} /><br />
            </div>
            <Container fluid className='p-0'>

                <div className="con-intro">
                    <h3>Contact us</h3>
                    <p>Please give us a call or send us an email if you have any questions or need assistance with your Power Platform solutions.</p>
                </div>

                {EmailSent ? <div className='m-5 h-50' ><div class="alert alert-success pt-10 p-5" role="alert">
                    Email Sent Successfully
                </div><br /><br /><br /><br /><br /><br /><br /><br /><br /></div> :
                    <form onSubmit={onsubmit} className='px-5 py-2 my-4' >
                        <div>
                            <label htmlFor="name" class="form-label">Name:</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                class="form-control"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="mobile" class="form-label mt-3">Mobile:</label>
                            <input
                                type="tel"
                                id="mobile"
                                name="mobile"
                                class="form-control"
                                value={formData.mobile}
                                onChange={handleChange}
                                pattern="[0-9]{10}"
                                placeholder='10 digit mobile number'
                                required
                            />
                            <label class="form-label">{mobileValidation}</label>
                        </div>
                        <div>
                            <label htmlFor="email" class="form-label">Email:</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="name@example.com"
                                class="form-control"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="message" class="form-label mt-3">Message:</label>
                            <textarea
                                id="message"
                                name="message"
                                class="form-control"
                                value={formData.message}
                                onChange={handleChange}
                                rows="4"
                                required
                            />
                        </div>
                        <div class="d-grid m-5">
                            <button type="submit" className='btn btn-primary btn-lg' >Submit</button>
                        </div>
                    </form>
                }

            </Container >
        </>
    );
}

export default Contactus;

