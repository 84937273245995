import React from 'react'
import Container from 'react-bootstrap/Container'
import { AiOutlineArrowRight } from 'react-icons/ai'
import Button from 'react-bootstrap/Button'
import governance from '../images/PPStrategiesSmall.png'
import customApp from '../images/CustomApp.png'
import Support from '../images/SupportBack.png'
import Header from './header'


function Home() {
    return (
        <>
            <Header />
            <Container fluid className='p-0'>

                <div className="intro">
                    <h2>What we do</h2>
                    <p>We specialise in Power Platform consulting, dedicated to helping businesses unlock the platform's full potential and maximize their return on investment.</p><p>
                        Whether you're embarking on your governance journey, developing custom apps, or migrating legacy systems to the Power Platform, our team is here to provide expert advice and hands-on guidance. We're committed to ensuring you achieve your goals efficiently and effectively, leveraging the power of the platform to drive your success.</p>
                </div>

                <div class="row">
                    <div class="column">
                        <div className='imgDiv'><img alt='Governance' src={governance} class="home" /></div> <div className='contentDiv' ><h2>Governance </h2>
                            <p>We offer strategic advice and guidance on leveraging Microsoft cloud products to achieve your business goals.</p>
                            <p>Our services include evaluating your current processes, identifying opportunities for improvement, and developing comprehensive environmental strategies.</p><p> Additionally, we provide expert Application Lifecycle Management (ALM) guidance to ensure seamless implementation and long-term success.
                            </p><br />
                            <Button variant="btn btn-primary" href="/governance">Learn more <AiOutlineArrowRight /></Button>
                        </div></div>
                    <div class="column alternate">
                        <div className='imgDiv'><img alt='Custom Development' src={customApp} class="home" /></div> <div className='contentDiv'><h2>Development and integration </h2>
                            <p>We create custom applications using Power Apps to address specific business needs, seamlessly integrating with other Microsoft services like SharePoint, Dataverse, and more. Our expertise covers various development approaches, including Model-Driven apps, Canvas apps, and Power Pages sites.</p><p>
                                In addition, we offer integration services to connect disparate systems and applications, ensuring a smooth and consistent flow of data across your organization. By leveraging Power Automate, we can streamline and automate repetitive tasks and workflows, enhancing efficiency and reducing manual effort. This includes automating approvals, notifications, and data entry tasks, allowing your team to focus on more strategic activities.
                            </p>
                            <Button variant="btn btn-primary" href="/development">Learn more <AiOutlineArrowRight /></Button>
                        </div></div>
                    <div class="column">
                        <div className='imgDiv'><img alt='Support and Training' src={Support} class="home" /></div> <div className='contentDiv'><h2>Support and Training </h2>
                            <p>
                                We offer comprehensive support and training services to ensure your team is fully equipped to manage and maintain your Power Platform solutions.</p>
                            <p> Our support services include troubleshooting, ongoing maintenance, and proactive monitoring of your Power Platform environment. Additionally, we provide tailored training to help your team fully understand the platform's capabilities and confidently leverage its features.
                            </p>
                            <Button variant="btn btn-primary" href="/support">Learn more <AiOutlineArrowRight /></Button>
                        </div></div>

                </div>
            </Container >
        </>
    );
}

export default Home;


/*<ul>
<li><h4>Power Apps for quick and easy mobile capable solutions,</h4></li>
<li><h4>Power Automate for business process automation and integration, </h4></li>
<li><h4>Dynamics 365 for enterprise business solutions and </h4></li>
<li><h4>Collaboration using Microsoft 365.</h4></li>

 <h4>Today there are so many cloud technologies, it is hard to know which one can solve your business problem.</h4>
                    <h4>We can help analyse your business requirements and identify a technology stack best suited for your needs and budget.</h4>
                    <h4>We can design and develop solutions to cater for your business unique needs.</h4>
</ul>*/