import React from 'react'
import Container from 'react-bootstrap/Container'
import Logo from '../images/logo2.png'
import Canvas from '../images/Canvas.png'
import PowerAuto from '../images/PowerAutomate.png'
import MDA from '../images/MDA.png'
import Copilot from '../images/Copilot.png'


function Development() {
    return (
        <>
            <div className="dev-image">
                <img src={Logo} alt='Azion' style={{ "width": "50%", "minWidth": "250px" }} /><br />
                <h2>POWER PLATFORM DEVELOPERS</h2>
            </div>
            <Container fluid className='p-0'>

                <div className="intro">
                    <h3>Development and integration</h3><br />
                    <p>Citizen developers can bring their unique business ideas to life with Power Apps, but sometimes they need expert support to take their applications to the next level. Our team of professional developers can create high-quality applications using Model-Driven, Canvas, and Power Pages, tailored to meet your specific business needs.
                    </p><p>
                        Additionally, our experts can seamlessly integrate these apps with other Microsoft services like SharePoint, Dataverse, and other available services, ensuring cohesive and powerful solutions for your organization.
                    </p><br />
                </div>

                <div className="row">
                    <div className="column">
                        <div className='imgDiv'><img alt='Model Driven Apps' src={MDA} class="home" style={{ "background-color": "#ffffff" }} /></div> <div className='contentDiv' ><h3>Model Driven Apps</h3>
                            <p>Model-Driven Apps (MDA) are designed for optimal use on desktops and tablets, making them ideal for applications that need to be accessed by a large number of users from various locations. </p><p>These apps leverage Dataverse to manage and store data, allowing for sophisticated data models, advanced security measures, and complex business logic.
                            </p><p>
                                Our team brings extensive expertise in both database design and customization. We are proficient in various web technologies, to tailor Model-Driven Apps to meet your unique requirements. </p><p>This expertise ensures that your applications are not only functional but also optimized for performance and scalability.</p>
                            <br />
                        </div>
                    </div>
                    <div className="column alternate">
                        <div className='imgDiv'><img alt='Canvas Apps' src={Canvas} class="home" /></div> <div className='contentDiv' ><h3>Canvas Apps</h3>
                            <p>
                                Canvas Apps are designed primarily for mobile devices, making them an ideal solution for applications that need to be accessed by a smaller, targeted group of users from anywhere. These apps offer a high degree of flexibility and customization, allowing for a tailored user experience that meets specific business needs.</p><p>

                                One of the key strengths of Canvas Apps is their ability to connect to a diverse array of data sources. They can seamlessly integrate with:</p>
                            <ul>
                                <li>SharePoint: For managing and accessing SharePoint lists and libraries</li>
                                <li>Dataverse: For leveraging structured data and business logic</li>
                                <li>Excel: For incorporating data from spreadsheets</li>
                                <li>SQL Server: For connecting to relational databases</li>

                            </ul>
                            <p>Additionally, Canvas Apps provide an intuitive drag-and-drop interface for designing and customizing the app's layout and functionality, enabling the creation of visually appealing and user-friendly applications. This flexibility ensures that the apps not only meet functional requirements but also deliver a unique and engaging user experience.
                            </p>
                        </div></div>
                    <div className="column">
                        <div className='imgDiv'><img alt='Power Automate' src={PowerAuto} class="home" /></div><div className='contentDiv'><h3>Power Automate for integration </h3>
                            <p>Power Automate offers seamless connectivity to hundreds of services, encompassing both Microsoft and third-party platforms. This extensive connectivity enables the automation of workflows, integration of disparate systems, and efficient data migration between various applications.
                            </p><p>
                                Our team of experts specializes in designing and implementing automated workflows that streamline your business processes, saving you both time and money. By automating mundane and repetitive tasks, we help you eliminate manual effort, reduce errors, and enhance overall operational efficiency.</p><p> Let us transform your workflow challenges into streamlined solutions, allowing you to focus on more strategic and value-added activities.
                            </p>

                        </div>
                    </div>
                    <div className="column alternate">
                        <div className='imgDiv'><img alt='Copilot' src={Copilot} class="home" /></div> <div className='contentDiv' ><h3>AI & Copilot</h3>
                            <p>Copilot is an advanced AI-powered tool designed to accelerate code development with minimal effort. By simply describing your requirements in the provided interface, Copilot can assist in creating tables, generating Power Automate flows, and more, significantly speeding up development activities.
                            </p><p>
                                Our team of experts leverages Copilot to enhance productivity and streamline development processes. We specialize in setting up and integrating Copilot into your workflow, showcasing how it can transform tasks such as creating virtual agents or summarizing documents in SharePoint. </p><p>By harnessing the power of Copilot, we help businesses achieve greater efficiency, reduce manual coding effort, and unlock the full potential of AI-driven solutions.
                            </p>
                        </div>


                    </div>
                </div>

            </Container >
        </>
    );
}

export default Development;

