import React from 'react'
import Container from 'react-bootstrap/Container'
import Logo from '../images/logo2.png'
import Sup from '../images/Support.png'
import Training from '../images/Training.png'

function Support() {
    return (
        <>
            <div className="sup-image">
                <img src={Logo} alt='Azion' style={{ "width": "50%", "minWidth": "250px" }} /><br />
                <h2>POWER PLATFORM SUPPORT</h2>
            </div>
            <Container fluid className='p-0'>

                <div className="intro">
                    <h3>Support and training</h3><br />
                    <p>In the ever-evolving landscape of technology, challenges can arise. Our team of experts is dedicated to providing comprehensive support and training services to ensure your team is well-equipped to effectively manage and maintain your Power Platform solutions.
                    </p><p>
                        Our support services encompass troubleshooting, regular maintenance, and proactive monitoring of your Power Platform environments. Additionally, we offer tailored training programs to help your team gain a thorough understanding of the platform and its full range of capabilities. This combination of expert support and strategic training ensures that you can optimize the performance and utility of your Power Platform solutions.
                    </p> <br />
                </div>

                <div className="row">
                    <div className="column">
                        <div className='imgDiv'><img alt='Tailored training' src={Training} class="home" style={{ "background-color": "#ffffff" }} /></div> <div className='contentDiv' ><h3>Tailored training</h3>
                            <p> While many training videos are available, sometimes you need a solution tailored to your specific business needs or an opportunity to ask detailed questions.
                            </p><p>
                                Our team is committed to providing a customized training experience designed specifically for your organization. We offer personalized sessions that address your unique requirements and answer any questions you may have on particular topics.<p></p>This bespoke approach ensures that your team gains the most relevant and practical insights for your business.
                            </p>
                            <br />
                        </div>
                    </div>
                    <div className="column alternate">
                        <div className='imgDiv'><img alt='Support' src={Sup} class="home" /></div> <div className='contentDiv' ><h3>Support and maintenance</h3>
                            <p>
                                We are here to assist you when challenges arise. If you encounter any issues or require support, simply reach out to us.</p><p> Our team is ready to provide expert troubleshooting and resolve any problems efficiently, ensuring minimal disruption to your operations. Our commitment to support and maintenance means you can rely on us for timely and effective solutions whenever you need them.                            </p>
                        </div></div>

                </div>

            </Container >
        </>
    );
}

export default Support;

