import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { history } from './history';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '84cf1765-f902-473c-9704-3f892dce2c50',
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {

            [reactPlugin.identifier]: { history: history }

        }
    }
});
appInsights.loadAppInsights();

export { appInsights, reactPlugin };